<template>
<div>
        <v-card>
            <v-card-title>
                <v-icon color="primary"> mdi-information </v-icon>
                <span class="pl-2">Πληροφορίες Σχολείου</span>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="4">
                        <p class="font-weight-bold">Μαθητές</p>
                        <div class="mt-4">
                            <p>{{ school.student_count }}</p>
                        </div>
                    </v-col>
                    <!-- drivers -->
                    <v-col cols="12" md="4">
                        <p class="font-weight-bold">Οδηγοί</p>
                        <div class="mt-4">
                            <p>{{ school.driver_count }}</p>
                        </div>
                    </v-col>
                    <!-- buses -->
                    <v-col cols="12" md="4">
                        <p class="font-weight-bold">Σχολικά</p>
                        <div class="mt-4">
                            <p>{{ school.bus_count }}</p>
                        </div>
                    </v-col>

                    <!-- stops -->
                    <v-col cols="12" md="4">
                        <p class="font-weight-bold">Στάσεις</p>
                        <div class="mt-4">
                            <p>{{ school.stop_count }}</p>
                        </div>
                    </v-col>

                    <!-- routes -->
                    <v-col cols="12" md="4">
                        <p class="font-weight-bold">Διαδρομές</p>
                        <div class="mt-4">
                            <p>{{ school.route_count }}</p>
                        </div>
                    </v-col>

                    <!-- trips -->
                    <v-col cols="12" md="4">
                        <p class="font-weight-bold">Δρομολόγια</p>
                        <div class="mt-4">
                            <p>{{ school.trip_count }}</p>
                        </div>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-card class="my-4">
            <v-card-title>
                <v-icon color="primary"> mdi-information </v-icon>
                <span class="pl-2">Tax Information</span>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="4">
                        <p class="font-weight-bold">Invoice Data Title</p>
                        <div class="mt-4">
                            <p>{{ school.invoice_data_title }}</p>
                        </div>
                    </v-col>

                    <v-col cols="12" md="4">
                        <p class="font-weight-bold">Company Name</p>
                        <div class="mt-4">
                            <p>{{ school.company_name }}</p>
                        </div>
                    </v-col>

                    <v-col cols="12" md="4">
                        <p class="font-weight-bold">Company Job</p>
                        <div class="mt-4">
                            <p>{{ school.company_job }}</p>
                        </div>
                    </v-col>

                    <v-col cols="12" md="4">
                        <p class="font-weight-bold">Company Address</p>
                        <div class="mt-4">
                            <p>{{ school.company_address }}</p>
                        </div>
                    </v-col>

                    <v-col cols="12" md="4">
                        <p class="font-weight-bold">Company Address PO Box</p>
                        <div class="mt-4">
                            <p>{{ school.company_address_po_box }}</p>
                        </div>
                    </v-col>

                    <v-col cols="12" md="4">
                        <p class="font-weight-bold">Company City</p>
                        <div class="mt-4">
                            <p>{{ school.company_city }}</p>
                        </div>
                    </v-col>

                    <v-col cols="12" md="4">
                        <p class="font-weight-bold">Company Country</p>
                        <div class="mt-4">
                            <p>{{ school.company_country }}</p>
                        </div>
                    </v-col>

                    <v-col cols="12" md="4">
                        <p class="font-weight-bold">Company VAT</p>
                        <div class="mt-4">
                            <p>{{ school.company_vat }}</p>
                        </div>
                    </v-col>

                    <v-col cols="12" md="4">
                        <p class="font-weight-bold">Company GEMI Number</p>
                        <div class="mt-4">
                            <p>{{ school.company_gemi_number }}</p>
                        </div>
                    </v-col>

                    <v-col cols="12" md="4">
                        <p class="font-weight-bold">Tax Office</p>
                        <div class="mt-4">
                            <p>{{ school.tax_office }}</p>
                        </div>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
</div>
</template>

<script>
import AvatarImageComponent from "../../components/AvatarImageComponent.vue";

export default {
    name: "schoolCard",
    props: {
        school: {
            type: Object,
            required: true,
        },
    },
    components: {
        AvatarImageComponent,
    },
};
</script>
